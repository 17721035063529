









































































































































import Vue from "vue";
import { ApplicationChildResponse, ApplicationResponse } from "@/api/application/types/Responses";
import ParentRelationshipType from "@/enums/ParentRelationshipType";
import { ProcessApplicationRequest, ProcessChildToClassRequest } from "@/api/application/types/Requests";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { TranslateResult } from "vue-i18n";
import SchoolClassClient from "@/api/school-class/SchoolClassClient";
import SchoolYearClient from "@/api/school-year/SchoolYearClient";
import { AllItems } from "@/api/AbstractClient";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { FamilyResponse, ParentRelationshipTypeResponse } from "@/api/family/types/Responses";
import EnumerationClient from "@/api/enum/EnumerationClient";
import { ChildResponse } from "@/api/child/types/Responses";
import ApplicationClient from "@/api/application/ApplicationClient";

export default Vue.extend({
  name: "ProcessApplicationForm",
  mixins: [validationMixin],
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    application: {
      type: ApplicationResponse,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmit: false,
    isBusySchoolClasses: false,
    isBusyParentRelationshipTypes: false,
    // enum
    schoolClasses: [] as SchoolClassResponse[],
    parentRelationshipTypes: [] as ParentRelationshipTypeResponse[],
    // form data
    form: {
      merge_families: true,
      parent_relationship_type: ParentRelationshipType.MARRIED_COUPLE,
      children_to_school_classes: [],
    } as ProcessApplicationRequest,
  }),
  validations: {
    form: {
      merge_families: {},
      parent_relationship_type: { required },
      children_to_school_classes: { required },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.isBusyParentRelationshipTypes || this.isBusySchoolClasses;
    },
    parentRelationshipTypesErrors(): TranslateResult[] {
      return []; // TODO
    },
    mergeFamiliesErrors(): TranslateResult[] {
      return []; // TODO
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.isBusySubmit = true;
      ApplicationClient.processApplication(this.application.id, this.form)
        .then((response: FamilyResponse) => {
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onChildToSchoolChange(child: ApplicationChildResponse, schoolClassId: number): void {
      let relation = this.form.children_to_school_classes.find((relation: ProcessChildToClassRequest) => relation.application_child === child.id);
      if (!relation) {
        relation = {
          application_child: child.id,
          school_class: schoolClassId,
        } as ProcessChildToClassRequest;
        this.form.children_to_school_classes.push(relation);
      } else {
        relation.school_class = schoolClassId;
      }
      this.$v.form.children_to_school_classes?.$touch();
    },
    onClose(): void {
      this.$emit("onClose");
    },
    schoolClassErrors(child: ApplicationChildResponse): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.children_to_school_classes?.$dirty) return errors;
      const relation = this.$v.form.children_to_school_classes.$model.find((relation: ProcessChildToClassRequest) => relation.application_child === child.id);
      if (!relation) {
        errors.push(this.$t("applications.validation.child_to_school.required"));
      }
      return errors;
    },
    fetchSchoolClasses(): void {
      this.isBusySchoolClasses = true;
      SchoolClassClient.getSchoolClasses(this.schoolId, this.application.school_year.id, new AllItems())
        .then((response) => {
          this.schoolClasses = response.items;
        })
        .finally(() => {
          this.isBusySchoolClasses = false;
        });
    },
    fetchParentRelationshipTypes(): void {
      this.isBusyParentRelationshipTypes = true;
      EnumerationClient.getParentRelationshipTypes()
        .then((response: ParentRelationshipTypeResponse[]) => {
          this.parentRelationshipTypes = response;
        })
        .finally(() => {
          this.isBusyParentRelationshipTypes = false;
        });
    },
  },
  created(): void {
    this.fetchSchoolClasses();
    this.fetchParentRelationshipTypes();
  },
});
