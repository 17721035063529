



















import Vue from "vue";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import HelpMessage from "@/components/layout/HelpMessage.vue";
import RestrictionsOverview from "@/components/subscription/misc/RestrictionsOverview.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { SchoolResponse } from "@/api/school/types/Responses";
import PageTitle from "@/components/layout/PageTitle.vue";
import ApplicationsTable from "@/components/applications/table/ApplicationsTable.vue";

export default Vue.extend({
  name: "Applications",
  components: { RestrictionsOverview, HelpMessage, ApplicationsTable, PageTitle, DefaultLayout },
  computed: {
    schoolId(): number | null {
      const school = this.$store.getters["Setting/getSelectedSchool"] as SchoolResponse | null;
      return school !== null ? school.id : null;
    },
    schoolYearId(): number | null {
      const schoolYear = this.$store.getters["Setting/getSelectedSchoolYear"] as SchoolYearResponse | null;
      return schoolYear !== null ? schoolYear.id : null;
    },
  },
  methods: {
    onProcess(): void {
      (this.$refs["restrictions-overview"] as any).refresh();
    },
  },
});
