















































import Vue from "vue";
import DataTable, { DataTableAction, DataTableHeader } from "@/components/data-table/DataTable.vue";
import AbstractClient from "@/api/AbstractClient";
import ApplicationClient from "@/api/application/ApplicationClient";
import _ from "lodash";
import { DataTableFilterType } from "@/components/data-table/DataTableFilter.vue";
import ProcessApplicationForm from "@/components/applications/form/ProcessApplicationForm.vue";
import { ApplicationResponse } from "@/api/application/types/Responses";
import ApplicationDetail from "@/components/applications/ApplicationDetail.vue";

export default Vue.extend({
  name: "ApplicationsTable",
  components: { ApplicationDetail, ProcessApplicationForm, DataTable },
  props: {
    schoolId: {
      type: Number,
      required: true,
    },
    schoolYearId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    // dialog
    processApplicationDialog: false,
    applicationDetailDialog: false,
    // application to show in detail/process dialog
    applicationToProcess: null as ApplicationResponse | null,
    applicationToDetail: null as ApplicationResponse | null,
  }),
  watch: {
    processApplicationDialog(value: boolean): void {
      if (!value) {
        this.applicationToProcess = null;
      }
    },
    applicationDetailDialog(value: boolean): void {
      if (!value) {
        this.applicationToDetail = null;
      }
    },
  },
  computed: {
    client(): AbstractClient {
      return new ApplicationClient();
    },
    clientFunction(): any {
      return ApplicationClient.getApplications;
    },
    clientFunctionParameters(): any[] {
      return [this.schoolYearId];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("applications.application_number")),
          value: "id",
          filter: {
            key: "id",
            type: DataTableFilterType.TEXT,
          },
          export: {
            json_paths: ["$.items[*].id"],
          },
        },
        {
          text: _.toString(this.$t("applications.children")),
          value: "application_children",
          filter: {
            key: ["application_child.first_name", "application_child.last_name"],
            type: DataTableFilterType.TEXT,
          },
        },
        {
          text: _.toString(this.$t("applications.created_at")),
          value: "created_at",
          filter: {
            key: "created_at",
            type: DataTableFilterType.DATE_RANGE,
          },
          export: {
            json_paths: ["$.items[*].created_at"],
          },
        },
        {
          text: _.toString(this.$t("applications.processed_at")),
          value: "processed_at",
          filter: {
            key: "processed_at",
            type: DataTableFilterType.DATE_RANGE,
          },
          export: {
            json_paths: ["$.items[*].processed_at"],
          },
        },
        {
          text: _.toString(this.$t("applications.processed_by")),
          value: "processed_by",
          filter: {
            key: "processed_by",
            type: DataTableFilterType.TEXT,
          },
        },
        {
          text: _.toString(this.$t("applications.process_status")),
          value: "is_processed",
          filter: {
            key: "is_processed",
            type: DataTableFilterType.BOOLEAN,
            textYes: this.$t("applications.processed"),
            textNo: this.$t("applications.unprocessed"),
          },
          export: {
            name: this.$t("applications.processed"),
            json_paths: ["$.items[*].is_processed"],
          },
        },
      ];
    },
    actions(): DataTableAction[] {
      return [
        {
          name: "detail",
          icon: "mdi-magnify",
          default: true,
          onClick: (application: ApplicationResponse): void => {
            this.applicationToDetail = application;
            this.applicationDetailDialog = true;
          },
        },
        {
          name: "process",
          icon: "mdi-cog-sync-outline",
          onClick: (application: ApplicationResponse): void => {
            this.applicationToProcess = application;
            this.processApplicationDialog = true;
          },
          disabled: (application: ApplicationResponse): boolean => {
            return application.is_processed;
          },
        },
      ];
    },
  },
  methods: {
    onSubmit(): void {
      this.processApplicationDialog = false;
      (this.$refs["table"] as any).refresh();
      this.$snackbarSuccess(this.$t("applications.applications_processed"));
    },
  },
});
