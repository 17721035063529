
























































import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import NewApplicationForm from "@/components/applications/form/NewApplicationForm.vue";

export default Vue.extend({
  name: "NewApplication",
  components: { NewApplicationForm, DefaultLayout },
  data: () => ({
    status: null as boolean | null,
  }),
  methods: {
    onSubmit(): void {
      this.status = true;
    },
    onError(): void {
      this.status = false;
    },
  },
});
